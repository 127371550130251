
.main-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.message-container {
    padding: $grid-gutter-width;
    margin: 0 auto;
    text-align: center;
    @include font-size(2rem);

    @include media-breakpoint-up(sm) {
        @include font-size(3rem);
    }
    .subtext {
        @include font-size(1.25rem);
        margin-top: $grid-gutter-width;
        @include media-breakpoint-up(sm) {
            max-width: 75vw;
            @include font-size(1.5rem);
        }
        &.first-subtext {
            margin-top: $grid-gutter-width*2;
        }
    }
}

h1 {
    margin-bottom: $grid-gutter-width * 2;
    @include font-size(5rem);

    @include media-breakpoint-up(sm) {
        @include font-size(6rem);
    }
}

.brand-container {
    width: 180px;
    max-width: 100%;
    margin: 0 auto;

    @include media-breakpoint-up(sm) {
        width: 220px;
    }

    @include media-breakpoint-up(md) {
        width: 250px;
    }

    @include media-breakpoint-up(xl) {
        width: 280px;
    }
}

.brand-svg {
    width: 100%;
    height: auto;

    .brand-gradient-primary {
        stop-color: rgba($secondary, 1);
        stop-opacity: 1;
    }

    .brand-gradient-secondary {
        stop-color: rgba($primary, 1);
        stop-opacity: 1;
    }

    .brand-text {
        fill: $black;
    }

    &.brand-svg-reverse .brand-text {
        fill: $white;
    }

    &.brand-svg-monotone {
        .brand-logo {
            fill: $black;
        }

        &.brand-svg-reverse .brand-logo {
            fill: $white;
        }
    }

}
